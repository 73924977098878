import * as React from "react"
import { graphql } from "gatsby"
import "../assets/styles/main.scss"
import BackgroundImage from "gatsby-background-image"
import Gallery from "../components/gallery"
import Layout from "../components/layout"
import InlineSVG from "../components/inlineSvg"
import Img from "gatsby-image"
import ContactButton from "../components/contactButton"
import { Col, Container, Row } from "react-bootstrap"

const Index = ({ data }) => {

  return <Layout>

    <BackgroundImage
      Tag="header"
      id='bannerImage'
      className="container d-flex flex-column mb-5"
      fluid={data.banner.image.fluid}
      backgroundColor={`#040e18`}
      critical={true}
    >
      <Row className="no-gutters justify-content-center mb-auto">
        <Col xs={8} md={6} id="header-signature">
          <InlineSVG svg={data.signature.svg}/>
        </Col>
      </Row>
    </BackgroundImage>

    <Container fluid={true} id="container">

      <Row as='main' className="align-items-center text-center" id="banner-blurb">
        <Col md={6} className="mb-5 font-weight-light lead">
          <p><strong>Born in NYC and graduate of</strong><br />
            School of Music and Art, NY<br />
            School of Visual Arts (Scholarship), NY<br /></p>
          <p><strong>Removed to London and graduate of</strong><br />
            Hornsey College of Art, London</p>
          <p className="mt-2">I work in watercolour, pastel and oil</p>

          <div className="mt-5">
            <ContactButton/>
          </div>
        </Col>
        <Col md={6} sm={8} className="mb-5 px-5 mx-auto">
          <Img fluid={data.tony.fluid} loading='eager' className='shadow' alt='Tony Fandino' />
        </Col>
      </Row>
    </Container>

    {data.sections.group.map((section, i) =>
      <Container fluid={true} key={i} className='mb-4'>
        <Row as='section'>
          <Col>
            <header className="my-4">
              <h2>{section.fieldValue}</h2>
            </header>
          </Col>
        </Row>

        <Gallery images={section.nodes}/>
      </Container>
    )}

    <Container fluid={true}>
      <Row as='section' className="text-center">
        <Col>
          <header>
            <h2>Other Albums and Exhibitions</h2>
            <p>My work is also exhibited at the following websites and events.<br />Choose one for more information.
            </p>
          </header>

          <Row className="my-4 justify-content-center">
            <Col md={6} lg={4} className="mb-3 mb-md-0">
              <a className="btn btn-lg btn-primary btn-block"
                 href="https://www.parallaxaf.co/" target="_blank" rel='noreferrer'>
                Parallax Art Fair<br />
                <span className="font-weight-light">Chelsea</span>
              </a>
            </Col>
            <Col md={6} lg={4}>
              <a className="btn btn-lg btn-primary btn-block" href="http://www.artupclose.com/tony-fandino-1"
                 target="_blank" rel='noreferrer'>
                Art UpClose<br />
                <span className="font-weight-light">New York</span>
              </a>
            </Col>
          </Row>
          <div className='my-5'>
            Would you like to meet the artist?<br/>
            Come along to the Parallax Art Fair, which usually runs three times a year and is free to attend.
          </div>
          <ContactButton size='lg'/>
        </Col>
      </Row>

      <Row as='footer' className="my-5">
        <Col>
          <div className="col-md-12">
            &copy; Anthony Fandino &middot; All Rights Reserved<br />
            <span className='text-muted'>No part of this site may be reproduced in whole<br/> or in part in any manner without permission</span>
          </div>
        </Col>
      </Row>

    </Container>
  </Layout>
}



export default Index;

export const query = graphql`
{
  banner: contentfulImage(homepageBanner: {eq: true}) {
    image {
      fluid(quality: 70, maxWidth: 2400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }

  signature: contentfulAsset(contentful_id: {eq: "wicpl1kshSiiMEe2IkFi1"}) {
    svg {
        absolutePath
        dataURI
        content
        relativePath
      }
  }
  
  tony: contentfulAsset(contentful_id: { eq: "Quy0ZPumrGHRzavUJszii"}) {
    fluid(quality: 70, maxWidth: 980) {
      ...GatsbyContentfulFluid_withWebp
    }
  }
  
  sections: allContentfulImage(sort: {fields: index, order: ASC}) {
    group(field: section___name) {
      fieldValue
      nodes {
        id
        caption
        medium
        height
        width
        price
        index
        image {
          fluid(quality: 50, maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        section {
          name
        }
      }
    }
  }
}
`
