import * as React from "react"
import { Component } from "react"
import Img from "gatsby-image"
import Lightbox from "./lightbox"
import { Col, Row } from "react-bootstrap"

interface GalleryProps {
  images: any[];
}

interface GalleryState {
  showLightbox: boolean;
  selectedImage: number;
}

class Gallery extends Component<GalleryProps, GalleryState> {

  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  handleClose = () => this.setState({ showLightbox: false });
  handleShow = () => this.setState({ showLightbox: true });

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
  }

  render () {

    const { images } = this.props;
    const { showLightbox, selectedImage } = this.state;

    return (
      <>
        <Row className="row-cols-2 row-cols-md-3">
          {images.map((image, i) =>
              <Col key={image.id} className="mx-auto thumbnail">
                <a onClick={e => this.handleClick(e, i)} href='#' title={`${image.caption} (${image.section.name})`}>
                  <Img fluid={image.image.fluid} className='img-fluid h-100' alt={`${image.caption} (${image.section.name})`} />
                </a>
              </Col>
          )}
        </Row>

        <Lightbox images={images} show={showLightbox} selectedImage={selectedImage} onClose={this.handleClose} />

      </>)
  }

}

export default Gallery
