import Helmet from "react-helmet"
import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ children }) => {

  const {opengraphImage} = useStaticQuery(query);

  return (
    <>
      <Helmet>
        <title>Tony Fandino &middot; Oil, Pastel and Watercolour Artist</title>
        <html lang="en" />
        <meta name="description" content="I paint England, London, Greece and Spain. Graduate of School of Music and Art (NY),School of Visual Arts (Scholarship, NY), Hornsey College of Art (London)."/>

        <meta property="og:title" content="Tony Fandino · Oil, Pastel and Watercolour Artist"/>
        <meta property="og:site_name" content="Tony Fandino · Oil, Pastel and Watercolour Artist"/>
        <meta property="og:url" content="https://tonyfandino.com"/>
        <meta property="og:description" content="I paint England, London, Greece and Spain. Born in NYC. Graduate of School of Music and Art (NY),School of Visual Arts (Scholarship, NY), Hornsey College of Art (London)."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content={'https:'+opengraphImage.file.url}/>
        <meta property="og:image:width" content={opengraphImage.file.details.image.width}/>
        <meta property="og:image:height" content={opengraphImage.file.details.image.height}/>
        <meta property="og:image:type" content={opengraphImage.file.contentType}/>
      </Helmet>
      {children}
    </>
  );
}

export default Layout;

const query = graphql`
  query {
  opengraphImage: contentfulAsset(contentful_id: {eq: "1yglro3T4XHQBC5k7X1fic"}) {
    file {
      url
      contentType
      details {
        image {
          height
          width
        }
      }
    }
  }
  }
`
