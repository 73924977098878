import * as React from "react"
import { Component } from "react"

interface InlineSvgProps {
  svg: any;
  className?: string;
}

// Render inline SVG with fallback non-svg images
class InlineSVG extends Component<InlineSvgProps> {
  render() {
    const { svg, className } = this.props;

    if (svg && svg.content) {
      // Inlined SVGs
      return <div className={className} dangerouslySetInnerHTML={{ __html: svg.content }} />
    } else {
      return null;
    }
  }

}

export default InlineSVG
