import * as React from "react"
import { Component } from "react"
import { Button, Modal } from "react-bootstrap"
import Img from "gatsby-image"

interface LightboxProps {
  selectedImage: number;
  images: any[];
  show: boolean;
  onClose: any;
}

interface LightboxState {
  selectedImage: number;
}

class Lightbox extends Component<LightboxProps, LightboxState> {

  state = {
    selectedImage: this.props.selectedImage
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedImage !== prevProps.selectedImage) {
      this.setState({selectedImage: this.props.selectedImage});
    }
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyUp, false)
  }

  handleKeyUp = e => {
    e.preventDefault()

    if (this.props.show) {
      // Left Arrow Key
      if (e.keyCode === 37) { this.goBack(); }

      // Right Arrow Key
      if (e.keyCode === 39) { this.goForward(); }

      // Escape key
      if (e.keyCode === 27) { this.props.onClose(); }
    }
  }

  canGoBack = () => {
    return this.state.selectedImage - 1 >= 0;
  }

  canGoForward = () => {
    return !!this.props.images[this.state.selectedImage + 1];
  }

  goBack = () => {
    if (this.canGoBack()) { this.setState({ selectedImage: this.state.selectedImage - 1 }); }

  }

  goForward = () => {
    if (this.canGoForward()) { this.setState({ selectedImage: this.state.selectedImage + 1 }); }
  }

  render () {

    const { images, show, onClose } = this.props;

    const {selectedImage} = this.state;

    return <Modal show={show} onHide={onClose} size={"xl"} centered={true}>
      <Modal.Header closeButton className='mb-2'>
        <Button variant='link' className='text-decoration-none text-muted mr-2 pl-0' onClick={e => this.goBack()} disabled={!this.canGoBack()}>
          <span className='mr-2'>&larr;</span> Prev
        </Button>
        <Button variant='link' className='text-decoration-none text-muted ml 2' onClick={e => this.goForward()} disabled={!this.canGoForward()}>
          Next <span className='ml-2'>&rarr;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>

        <Img fluid={images[selectedImage].image.fluid} />
      </Modal.Body>
      <Modal.Footer className='text-right'>
        {images[selectedImage].caption}
        {images[selectedImage].section?.name &&
        <> &middot; {images[selectedImage].section.name}</>
        }
        {images[selectedImage].height && images[selectedImage].width &&
        <><br/>{images[selectedImage].height}cm &times; {images[selectedImage].width}cm</>
        }
        {images[selectedImage].medium &&
        <><br/>{images[selectedImage].medium}</>
        }
        {images[selectedImage].price &&
        <><br/>£ {images[selectedImage].price.toLocaleString()}</>
        }
      </Modal.Footer>
    </Modal>;

  }

}

export default Lightbox
