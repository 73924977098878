import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Button from "react-bootstrap/Button"

interface ContactModalProps {
  size?: 'sm' | 'lg';
}

interface ContactModalState {
  show: boolean;
}

class ContactButton extends Component<ContactModalProps, ContactModalState> {

  state = {
    show: false
  }

  showModal = (e) => {
    e.preventDefault()
    this.setState({ show: !this.state.show })
  }

  closeModal = () => {
    this.setState({ show: false })
  }

  render() {

    const { size } = this.props;
    const { show } = this.state;

    return (<>
      <Button onClick={e => this.showModal(e)} size={size}>Contact Me</Button>
      <Modal show={show} onHide={e => this.closeModal()} centered={true}>
        <Modal.Header closeButton className='mb-2'/>
        <Modal.Body className='shadow'>
          <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSePuZoY3oyVFkMDShlMMa1EUHxA-Gm7bvAwaDRzX-UrHXSRNQ/viewform'
                  className='w-100' style={{minHeight: '65vh', border: 'none', display: 'block'}} />
        </Modal.Body>
      </Modal>
    </>)
  }

}

export default ContactButton
